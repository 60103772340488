.step3 .sec1 {
    grid-template-columns: repeat(5, 1fr) ;
    gap: 1.5rem;
}

.step3 .sec2 {
    grid-template-columns: repeat(2, 1fr) ;
    gap: 1rem;
}

.shareholder_form_container {
    border: 1px solid #adadad33;
    background: #fff;
    padding: 20px;
    margin: 10px 0;
    position: relative;
}

.title_remove {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shareholder_button {
    height: max-content;
    color: #1475f7;
    border: 1px solid #8080802e;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.shareholder_button:hover {
    background:#1475f7 ;
    color: #fff;
}

.align_middle {grid-template-columns: repeat(6, max-content)!important;}

.total_num_share_data_2{
background: #effdea!important;
font-weight: bold!important;
}

/* 1100 down - large */
@media only screen and (max-width: 1650px) {
    .align_middle {
        grid-template-columns: repeat(3, max-content)!important;
        gap: 0.5rem!important;
    }
}

/* 1024 down - laptop */
@media only screen and (max-width: 1024px) {
    .align_middle {
        grid-template-columns: repeat(2, max-content)!important;
        gap: 0.5rem!important;
    }
}

/* 800 down - tablet */
@media only screen and (max-width: 800px) {
    .step3 .sec1, .step3 .sec2, .step3 .sec3, .align_middle {
        grid-template-columns: 1fr!important;
        gap: 0.5rem;

    }
}


/* 600 down - small screen */
@media only screen and (max-width: 600px) {

}

/* 360 down - mobile */
@media only screen and (max-width: 360px) {

}