.phoneInput input {
    border: 0;
}

.step1 .sec1{
    grid-template-columns: max-content repeat(3, 1fr);
}

.step1 .sec2{
    grid-template-columns: repeat(2, 1fr);
}

.step1 .sec3{
    grid-template-columns: 0.45fr 1fr;
}

section.grid.sec1 label, section.grid.sec2 label, section.grid.sec3 label {
    font-weight: bold;
}

.statement i {
    margin-top: 10px;
    display: block;
}