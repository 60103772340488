@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap');

body {
    margin: 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8em;
    letter-spacing: -0.3px;
    scroll-behavior: smooth;
    color: #000;
    background-color: #fff;
}

main {
    min-height: 85.5vh;
}

main {
  content:"";
  background:url("../../assets/img/bg-lines5.png") no-repeat ;
  /* background-position: left bottom; */
  background-size: cover;
}

.flex {
    display: flex;
}

.left_sec {
    width: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-top: 55px;
}

.left_sec:before {
    content: url("../../assets/img/skwer_dots_2.png");
    position: absolute;
    top: -80px;
    left: 0;
    animation: MoveLR 15s linear infinite;
    opacity: .5;
  }

  @keyframes MoveLR {
    0%, 100% {
      left: 0;
    }
    50% {
      left: 120px;
    }
  }

.center_sec {
    width: 68%;
    margin: 10px;
    background: #ffffffb5;
    padding: 20px;
}

.center_sec:before {
    content: url("../../assets/img/h2-shape2.png");
    position: absolute;
    top: 0;
    right: 25%;
    animation: MoveUpDown 15s linear infinite;
  }
  
  @keyframes MoveUpDown {
    0%, 100% {
      top: 0;
    }
    50% {
      top: 100px;
    }
  }

.np_buttons {
    margin-top: 20px;
}

h2 {
font-size: 2em;
line-height: 1.3em;

}

h2:before {
    content: "";
    background: #0f67f6;
    width: 100px;
    height: 8px;
    position: absolute;
    margin-top: 40px;
    border-radius: 4px;
    box-shadow: 0 5px 9px rgb(15 103 246 / 68%);
  }

  .form_cont {
    padding-top: 1rem;
  }

.btn{
    border: none;
    color: #fff;
    padding: 7px 20px;
    text-decoration: none;
    transition: background .5s ease;
    -moz-transition: background .5s ease;
    -webkit-transition: background .5s ease;
    -o-transition: background .5s ease;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    outline: 0;
    text-align: center;
    background: #71bee5;
    position: relative;
    font-size: 20px;
    border-radius: 20px;
    line-height: 1;
    box-shadow: 0 10px 40px -10px rgb(0 130 255 / 30%);
    /* background-image: linear-gradient(to right, #7114EF , #1475F7 ); */
    background-image: linear-gradient(90deg, #1475F7 0%, #7114EF 50%, #1475F7) !important;
    padding: 0px 60px 0px 60px;
    border-radius: 58px 58px 58px 58px;
    font-size: 15px;
    line-height: 45px;
    box-shadow: 0px 6px 6px 0px rgb(55 80 244 / 30%);
    font-family: 'Fira Sans', sans-serif;
    font-size: 15px;
    color: #fff;
    font-weight: 700;
    background-size: 200%, 1px;
    background-position: 0%;
    }

button[disabled], button[disabled]:hover {
    color: #888d95;
    cursor: help;
    background-image: linear-gradient(90deg, #afafaf 0%, #afafaf 50%, #afafaf) !important;
}

.np_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.grid {
    display: grid;
    gap: 0.3rem;
    justify-content: left;
}

.block {display: block;}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

span.error {
    color: #1475f7;
}

.form-control {
    padding: 8px;
    background: #fff;
    border: 1px solid #2222224a;
    width: 93%;
    position: relative;
}

.form-control:hover {
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
}

.form-control:focus, input {
    color: #495057;
    background-color: #fff;
    border-color: #80bfff;
    outline: 0;
    /* -webkit-box-shadow: 0 0 0 0.2rem rgb(0 126 255 / 25%);
    box-shadow: 0 0 0 0.2rem rgb(0 126 255 / 25%); */
  }

.step1 .sec3 select {
    width: 33%;
    display: block;
}

.step1 label {
    font-size: 14px;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.w-10 {width: 50px;}

.align_middle {
    align-items: center;
}

.hidden {
  display: none;
}

section.divider_note span {
  display: block;
  background: #ecece8;
  padding: 15px;
  position: relative;
}

section.right_sec.right_summary {
  position: fixed;
  right: 0;
  top: 0;
  height: 95vh;
    overflow-x: scroll;
}


/* RIGHT */

.right_summary {
    background: url("../../assets/img/skwergroup_bg_net.png") no-repeat;
    background-position: top;
    color: #101130!important;
    line-height: 1.5em;
    width: 20%;
    padding: 20px;
    position: relative;
    transition: ease 1s;
    border-left: 1px solid #eef3f7;
  }

section.right_sec.right_summary h4:after {
    content: "";
    border-bottom: 1px solid #adadad;
    width: 50%;
    position: absolute;
    margin-top: 10px;
    right: 10px;
}

  span.skwer__label {
    display: flex;
    background-color: #fff;
    border-radius: 46px;
    -ms-box-shadow: 0 11px 27px rgba(0, 9, 71, 0.13);
    -o-box-shadow: 0 11px 27px rgba(0, 9, 71, 0.13);
    box-shadow: 0 11px 27px rgb(0 9 71 / 13%);
    margin-bottom: 20px;
    text-align: center;
    padding: 10px 5px 10px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  span.skwer__label img {
    width: 35px;
    height: 30px;
  }

section.right_sec.right_summary table tr td:first-child {
  padding-right: 10px;
  font-weight: 500;
}

.share_subtitle {
  font-weight: normal!important;
  padding: 2px 0;
}

.share_title {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/*==============SCROLL BAR=============*/
/* Works on Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: darkgrey;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

/*==============RESPONSIVE=============*/
  /* 1100 down - large */
  @media only screen and (max-width: 1100px) {
    section.right_sec.right_summary {
      width: 0;
      /* display: none; */
      padding: 0;
      display: none;
    }
    .center_sec {
      width: 90%;
    }

    .center_sec:before {
      right: 5%;
    }

  }

  /* 1024 down - laptop */
  @media only screen and (max-width: 1024px) {
  }

  /* 800 down - tablet */
  @media only screen and (max-width: 800px) {
    .step1 .sec1, .step1 .sec2, .step1 .sec3 {
      grid-template-columns: 1fr;
    }

    select.form-control, .step1 .sec3 select {
      width: 96%;
    }

    .np_buttons {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0px 15px 0 0;
    }

    .np_buttons button {
      margin-bottom: 20px;
    }

    .center_sec {
      padding: 10px;
    }
  }


  /* 600 down - small screen */
  @media only screen and (max-width: 600px) {

    select.form-control, .step1 .sec3 select {
      width: 98%;
    }

    .center_sec:before {
      display: none;
    }
  }

  /* 360 down - mobile */
  @media only screen and (max-width: 400px) {
    h2 {
      font-size: 1.8em;
    }

    .center_sec:before {
      display: none;
    }
  }