
.step2 .sec1{
    grid-template-columns: 80px repeat(2, 1fr);
    margin: 10px 0px;
}

.step2 .sec2{
    grid-template-columns: repeat(2, 1fr) repeat(2, 0.5fr);
}

.step2 .sec3{
    grid-template-columns: 80px 1fr;
    margin: 10px 0px;
}

.step2 .sec4{
    grid-template-columns: repeat(2, 1fr)  1fr 0.1fr;
}

.step2 .sec1{
    grid-template-columns: 80px repeat(2, 1fr);
    margin: 10px 0px;
}

section.grid.sec1_act_title{
    grid-template-columns: 80px repeat(1, 1fr);
    margin: 10px 0px;
}

.step2 section.grid.sec3 input {
    width: 96.5%;
}

.act_desc {margin-left: 40%;}

.sec_title {text-align: center;}

.step2 hr {
    border-style: dashed;
    border-color: #cccd;
    border-width: 1px;
    width: 97%;
}

.total_num_share_data {
    display: block;
    padding: 5.5px;
    background: #effdea;
    border: 1px solid #2222224a;
    font-weight: bold;
    width: 104%;
}

.cname_ar {text-align: right;}

.ar_comp_name i {
    font-size: 11px;
    color: gray;
}

.tooltip {

}

.tooltip .tooltip_content {
    display: none;
    
}

.tooltip:hover .tooltip_content {
    display: block;
}

.tooltip .tooltip_content {
    padding: 20px;
    border: 1px solid #cfcfcf;
    position: absolute;
    z-index: 2;
    background: #f4ebeb;
    margin-top: -80px;
}

/* 1100 down - large */
@media only screen and (max-width: 1100px) {
    section.right_sec.right_summary {
        width: 0;
        /* display: none; */
        padding: 0;
        display: none;
    }
    .center_sec {
    width: 90%;
    }

    .center_sec:before {
        right: 5%;
    }
}

/* 1024 down - laptop */
@media only screen and (max-width: 1024px) {
}

/* 800 down - tablet */
@media only screen and (max-width: 800px) {
    .step2 .sec1, .step2 .sec2, .step2 .sec3 {
        grid-template-columns: 1fr;
    }

    select.form-control, .step1 .sec3 select {
        width: 96%;
    }

    .np_buttons {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 15px 0 0;
    }

    .np_buttons button {
        margin-bottom: 20px;
    }
}


/* 600 down - small screen */
@media only screen and (max-width: 600px) {

    select.form-control, .step1 .sec3 select {
        width: 98%;
    }
}

/* 360 down - mobile */
@media only screen and (max-width: 360px) {

}