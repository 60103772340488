.step5 {
    grid-template-columns: 0.5fr 1fr 0.5fr;
}

.step5:before {
    content: url("../../../assets/img/skwer_dots_2.png");
    position: absolute;
    top: -80px;
    left: 0;
    animation: MoveLR 15s linear infinite;
    opacity: .5;
  }

  @keyframes MoveLR {
    0%, 100% {
      left: 0;
    }
    50% {
      left: 120px;
    }
  }

.thank_you_page {
    text-align: center;
}

.thank_you_page h3 {
    font-size: 35px;
    letter-spacing: 10px;
}

.thank_you_page p {
    font-size: 16px;
}