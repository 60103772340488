header img {
    width: 300px;
  }
header {
  padding:20px;
}

/*==============RESPONSIVE=============*/

/* 1100 down - large */
@media only screen and (max-width: 1100px) {
  section.right_sec.right_summary {
    width: 0;
    padding: 0;
    display: none;
  }
  .center_sec {
  width: 90%;
  }

  .center_sec:before {
    right: 5%;
  }

}

/* 1024 down - laptop */
@media only screen and (max-width: 1024px) {
}

/* 800 down - tablet */
@media only screen and (max-width: 800px) {
  header {
    text-align: center;
  }
}


/* 600 down - small screen */
@media only screen and (max-width: 600px) {

}

/* 360 down - mobile */
@media only screen and (max-width: 360px) {

}