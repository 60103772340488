/* Steps 
red : #db3a3a
pink: #ea4c89
green : rgb(48, 130, 78) #30824e 
yellow: rgb(240, 200, 17) f0c811
*/

.step {
    position: relative;
    min-height: 1em;
  }
  .step + .step {
    margin-top: 6.5em;
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 1em;
    padding-left: 1em;
  }
  .step.step-active {
    color: #1475F7!important;
    font-weight: 700;
  }
  .step.step-active .circle {
    background-color: #1475F7;
  }

  .step-active .title {
    /* color: #07a0ec!important; */
    font-weight: 700;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7114EF), to(#1475F7));
    background-image: -webkit-linear-gradient(to left, #7114EF, #1475F7);
    background-image: -moz-linear-gradient(to left, #7114EF, #1475F7);
    background-image: -ms-linear-gradient(to left, #7114EF, #1475F7);
    background-image: -o-linear-gradient(to left, #7114EF, #1475F7);
    background-image: linear-gradient(to left, #7114EF, #1475F7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#7114EF', endColorStr='#1475F7');
    background-color: transparent;
    background-clip: text;
    -o-background-clip: text;
    -ms-background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  } 

  .step-done .title {
    color: #34a853!important;
  } 
  .step.step-done .circle {
    background-color: #34a853;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Circle */
  .circle {
    background: #adadad;
    position: relative;
    line-height: 2em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    padding-top: 0.1rem;
    font-weight: bold;
    font-size: 20px;
    /* top: 3px; */
  }
  
  /* Vertical Line */
  .circle:after {
    content: ' ';
    position: absolute;
    display: block;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    /* width: 1px; */
    width: 2px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    /* background-color: rgba(0, 0, 0, 0.25); */
    background-color: #adadad;
    /*z-index: -1;*/
    z-index: 0;
    
  }
  .step:last-child .circle:after {
    display: none
  }
  
  /* Stepper Titles */
  .title {
    line-height: 1.5em;
    color:  #adadad; /*#28a745*/
    font-weight: 500;
  }
  .caption {
    font-size: 0.8em;
  }

/* RESPONSIVE STYLE */

.title, .order_summary h3 {
  font-size: 18px;
}

.title {
  display: block;
}

.circle {
    width: 2.2em;
    height: 2.1em;
}

.circle .fa {
  font-size: 16px;
}
  
  /* 1366 down - large */
  @media only screen and (max-width: 1366px) {
          
  }

  /* 1024 down - laptop */
  @media only screen and (max-width: 1024px) {
    
  }

  /* 700 down - tablet */
  @media only screen and (max-width: 700px) {
    .left_sec {
      width: 20%;
    }

    .center_sec {
      width: 80%;
    }
  }

  /* 600 down - small screen */
  @media only screen and (max-width: 400px) {
    .left_sec {
      width: 20%;
    }

    .center_sec {
      width: 80%;
    }
  }

  /* 360 down - mobile */
  @media only screen and (max-width: 360px) {

  }